import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { deleteTriggersNotifyMutation, postToggleMarketingEmailsMutation, postToggleNotificationsMutation, postTriggersNotifyMutation, postHttpRequestMutation, getHttpRequestQuery } from './queries';
export var actionCommandsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postToggleMarketingEmails: builder.mutation(postToggleMarketingEmailsMutation),
      postToggleNotifications: builder.mutation(postToggleNotificationsMutation),
      postTriggersNotify: builder.mutation(postTriggersNotifyMutation),
      deleteTriggerNotify: builder.mutation(deleteTriggersNotifyMutation),
      postHttpRequest: builder.mutation(postHttpRequestMutation),
      getHttpRequest: builder.query(getHttpRequestQuery)
    };
  }
});
actionCommandsApi.enhanceEndpoints({
  addTagTypes: ['ActionCommands'],
  keepUnusedDataFor: 5
});
var usePostToggleMarketingEmailsMutation = actionCommandsApi.usePostToggleMarketingEmailsMutation,
  _actionCommandsApi$en = actionCommandsApi.endpoints,
  postToggleMarketingEmails = _actionCommandsApi$en.postToggleMarketingEmails,
  postToggleNotifications = _actionCommandsApi$en.postToggleNotifications,
  deleteTriggerNotify = _actionCommandsApi$en.deleteTriggerNotify,
  postTriggersNotify = _actionCommandsApi$en.postTriggersNotify,
  postHttpRequest = _actionCommandsApi$en.postHttpRequest,
  getHttpRequest = _actionCommandsApi$en.getHttpRequest;
export { usePostToggleMarketingEmailsMutation, postToggleMarketingEmails, postToggleNotifications, deleteTriggerNotify, postTriggersNotify, postHttpRequest, getHttpRequest };