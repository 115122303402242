import React, { useMemo } from 'react';
import { connect } from 'shared/node_modules/react-redux';
import GamePage from './GamePage';
import { clearOnboarding } from 'shared/state/actions/currentOnboarding';
import { markOnboardingSeen } from 'shared/state/actions/ftue';

const mapStateToProps = ({ load: { initAppResponse }, currentOnboarding: { currentStep, ftueStep } }) => {
  return {
    onboardingStep: currentStep,
    ftueStep,
    rawLobbyBanners: initAppResponse?.LOBBY_BANNERS ?? {},
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearOnboarding: () => {
      dispatch(clearOnboarding());
    },
    markOnboardingSeen: () => {
      dispatch(markOnboardingSeen());
    },
  };
};

const MemoizedGamePage = ({ rawLobbyBanners, ...props }) => {
  const lobbyBanners = useMemo(() => Object.values(rawLobbyBanners), [rawLobbyBanners]);

  return <GamePage {...props} lobbyBanners={lobbyBanners} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedGamePage);

