import { connect } from 'shared/node_modules/react-redux';
import GameTile from './GameTile';
import { clearOnboarding } from 'shared/state/actions/currentOnboarding';
import { markOnboardingSeen } from 'shared/state/actions/ftue';
import { ONBOARDING_FLOWS } from 'shared/utils/onboarding';

const mapStateToProps = ({
  levelInfo,
  ftue,
  currentOnboarding: { currentStep, ftueStep, ftueGameId },
  load: { initAppResponse },
}) => {
  return {
    onboardingStep: currentStep,
    ftueStep,
    ftueGameId,
    lobbyBanners: initAppResponse?.LOBBY_BANNERS,
    userCanFavourite: parseInt(levelInfo.level) >= parseInt(ftue.onboardingsUnlockLevels[ONBOARDING_FLOWS.FAVOURITES]),
  };
};

const mapDispatchToProps = {
  clearOnboarding,
  markOnboardingSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameTile);
