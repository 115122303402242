import BonusLinkMessage from './BonusLinkMessage';
import { connect } from '../../node_modules/react-redux';
import { shiftPendingMessage as _shiftPendingMessage, clearFakeMessages as _clearFakeMessages } from '../../state/actions/pendingMessage';
var mapStateToProps = function mapStateToProps(state) {
  return {
    navState: state.navState.navState,
    pendingMessage: state.pendingMessages
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    shiftPendingMessage: function shiftPendingMessage() {
      dispatch(_shiftPendingMessage());
    },
    clearFakeMessages: function clearFakeMessages() {
      dispatch(_clearFakeMessages());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BonusLinkMessage);