import React from 'react';
import useDynamicPopups from 'shared/screens/DynamicPopups/hooks/useDynamicPopups';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import DynamicPopupsButtons from './components/DynamicPopupsButtons';
import LinkText from '../../components/LinkText/LinkText';
import { ModalImage, NotificationsPermissionWrapper, CustomerSupportWrapper } from './styledComponent';
import {
  ACTION_COMMANDS_EVENT_TYPE,
  ACTION_COMMANDS_TYPE,
  ACTION_COMMANDS_MODAL_TYPE,
} from 'shared/api/ActionCommandsInterface/constants';
import Emitter from 'shared/services/EmitterService';
import { EVENT_EMITTER_TYPE } from 'shared/constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const DynamicPopups = () => {
  const { modalImageSource, buttonsRendering, showLinkAccountButtons, modalType, onDisplayAction, timerSeconds } =
    useDynamicPopups();

  DynamicPopups.DismissHandler = () => {
    QueryParamProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
    });
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.SUBMIT_EVENT_REQUEST,
      data: { event: ACTION_COMMANDS_EVENT_TYPE.DISMISS_APP_MODAL, modalType, onDisplayAction },
    });
  };

  return (
    <>
      {modalImageSource && (
        <NotificationsPermissionWrapper modalType={modalType}>
          <ModalImage modalType={modalType} src={modalImageSource} resizeMode="contain" />
          {modalType === ACTION_COMMANDS_MODAL_TYPE.EMAIL_VERIFICATION && (
            <CustomerSupportWrapper>
              <LinkText target="e_blank" href={ENVIRONMENT_VARIABLES.ZENDESK_URL}>
                {getText(TEXT_KEY.NEED_HELP)}
              </LinkText>
            </CustomerSupportWrapper>
          )}
          <DynamicPopupsButtons
            showLinkAccountButtons={showLinkAccountButtons}
            buttonsRendering={buttonsRendering}
            modalType={modalType}
            onDisplayAction={onDisplayAction}
            timerSeconds={timerSeconds}
          />
        </NotificationsPermissionWrapper>
      )}
    </>
  );
};

DynamicPopups.hideDefaultBackground = true;

export default DynamicPopups;

