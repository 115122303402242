export default {
  /* Navigation Stacks */
  Lobby: 'LobbyStack',
  Root: 'RootStack',
  /* Not-Auth Screens */
  Start: 'StartScreen',
  Login: 'LoginScreen',
  SSOLogin: 'SSOLoginScreen',
  Signup: 'SignupScreen',
  FacebookLogin: 'FacebookLoginScreen',
  TermsAndConditions: 'TermsAndConditionsScreen',
  TrackingURL: 'TrackingURL',
  DoubleOptIn: 'DoubleOptIn',
  /* Lobby Screens */
  Home: 'HomeScreen',
  Slots: 'SlotGamesScreen' /** DO NOT USE THIS FOR STAR */,
  Tables: 'TableGamesScreen',
  VIP: 'VIPGamesScreen',
  Sports: 'SportsGamesScreen',
  VipAccessFlow: 'VipAccessScreen',
  Favourites: 'FavouritesScreen',
  FavouriteListDialogue: 'FavouriteListDialogue',
  FavouriteOnboardingDialogue: 'FavouriteOnboardingDialogue',
  FavouritesExpired: 'FavouritesExpired',
  FavouritesInformation: 'FavouritesInformation',
  FavouritesUnlocked: 'FavouritesUnlocked',
  Game: 'GameScreen',
  Shop: 'ShopScreen',
  FinishWebPurchase: 'finishpurchase',
  PurchaseFlow: 'PurchaseFlow',
  PurchaseSucceeded: 'PurchaseSucceeded',
  SportsAccessFlow: 'SportsAccessFlow',
  /* Modals */
  VideoAds: 'VideoAdsScreen',
  Congrats: 'CongratsScreen',
  Settings: 'SettingsScreen',
  News: 'NewsScreen',
  DailySpin: 'DailySpinScreen',
  SessionExpired: 'SessionExpiredScreen',
  LimitedTimeOffer: 'LimitedTimeOffer',
  RateUs: 'RateUsScreen',
  WatchAndEarnInfo: 'WatchAndEarnInfo',
  OutOfCoin: 'OutOfCoinScreen',
  LinkAccount: 'LinkAccountScreen',
  LinkAccountForm: 'LinkAccountForm',
  ConnectionLost: 'ConnectionLost',
  ComingSoon: 'ComingSoon',
  RewardCenterOnboarding: 'RewardCenterOnboarding',
  LinkCardOnboarding: 'LinkCardOnboarding',
  DailySpinOnboarding: 'DailySpinOnboarding',
  WelcomeBonus: 'WelcomeBonus',
  OneTimePassword: 'OneTimePassword',
  DynamicPopups: 'DynamicPopups',
  /* Critical Path Flows */
  UpgradeAccountFlow: 'UpgradeAccountFlow',
  CurrencySelector: 'CurrencySelectorScreen',
  LinkLoyaltyCard: 'LinkLoyaltyCard',
  KYCLockout: 'KYCLockoutScreen',
  /* Temporary Screens */
  Banner: 'BannerView',
  GameUnavailable: 'GameUnavailableView',
  GenericMessage: 'GenericMessage',
  BonusLinkMessage: 'BonusLinkMessage',
  CheatMenu: 'CheatMenu',
  RegisterRewardCard: 'RegisterRewardCard',
  ForgotPassword: 'ForgotPassword',
  AdBlocker: 'AdBlocker',
  SneakPeekOnboarding: 'SneakPeekOnboarding',
  Missions: 'MissionsScreen',
  Events: 'EventsScreen',
  RewardCenter: 'RewardCenterScreen',
  Social: 'SocialScreen'
};