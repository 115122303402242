import styled from 'styled-components';

export const GameListWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media only screen and (max-height: 600px) {
    margin-top: 6rem;
  }

  @media only screen and (max-height: 880px) and (max-width: 1300px) {
    margin-left: 3rem;
  }
`;

