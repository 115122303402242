import React, { useContext } from 'react';
import { ButtonText, ButtonWrapper, ButtonsContainer, FacebookLinkButton, MemberLinkButton } from '../styledComponent';
import Button from '../../../components/Button/Button';
import LinkAccountButtons from '../../Settings/Account/components/LinkAccount/DefaultLinkAccount';
import ThemeContext from 'shared/context/ThemeContext';
import useResendAndTimer from 'shared/screens/DynamicPopups/hooks/useResendAndTimer';
import { checkButtonTitleAndStatus } from 'shared/screens/DynamicPopups/utils/generateButtonsRendering';

const DynamicPopupsButtons = ({
  showLinkAccountButtons,
  buttonsRendering,
  modalType,
  onDisplayAction,
  timerSeconds,
}) => {
  const { DynamicPopupsButtons } = useContext(ThemeContext);

  return (
    <React.Fragment>
      {showLinkAccountButtons ? (
        <LinkAccountButtons
          rowWrapperStyles={DynamicPopupsButtons.RowWrapperLink}
          facebookLinkButtonStyles={FacebookLinkButton}
          memberLinkButtonStyles={MemberLinkButton}
          isActionCommand={true}
          modalType={modalType}
          onDisplayAction={onDisplayAction}
        />
      ) : (
        <RenderingButtons modalType={modalType} buttonsRendering={buttonsRendering} timerSeconds={timerSeconds} />
      )}
    </React.Fragment>
  );
};

const RenderingButtons = ({ buttonsRendering, timerSeconds, modalType }) => {
  const { DynamicPopupsButtons } = useContext(ThemeContext);
  const { timeLeft, triggerInterval } = useResendAndTimer(timerSeconds);

  return (
    <ButtonsContainer modalType={modalType} theme={DynamicPopupsButtons.ButtonsContainer}>
      {buttonsRendering.map(({ label, action, imageSource, buttonType }, index) => {
        const { buttonImage, disabled, buttonLabel, actionHandlerCallback } = checkButtonTitleAndStatus({
          label,
          timeLeft,
          triggerInterval,
          imageSource,
          buttonType,
        });
        return (
          <ButtonWrapper key={index} imageSource={imageSource}>
            <Button
              disabled={disabled}
              imageSource={buttonImage}
              label={buttonLabel}
              onClick={() => action(actionHandlerCallback)}
              textStyle={ButtonText}
            />
          </ButtonWrapper>
        );
      })}
    </ButtonsContainer>
  );
};

export default DynamicPopupsButtons;

