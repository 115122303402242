import React from 'react';
import { DividerDiv, StoreLogoAsset, LinkAssetHolder } from './styledComponents';
import { assetSource } from 'shared/utils/assetSource';

const BadgeContainer = ({url, badge}) => {
    return (
    <>
        <DividerDiv />
        <LinkAssetHolder href={url}>
        <StoreLogoAsset src={assetSource(badge)} alt="" />
        </LinkAssetHolder>
    </>
    )
}

export default BadgeContainer
