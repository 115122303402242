import React, { Component } from 'react';
import SHome from 'shared/screens/Home';
import Utils from 'shared/utils';
import GamePage from '../../components/GamePage';
import audio from 'shared/utils/audio';
import { MissionTutorialStepsKeys, shouldChangeTutorialStep } from 'shared/utils/tutorial';
import { GameListWrapper } from './styledComponents';

export default class Home extends Component {
  updateTutorialStep = () => {
    const { tutorialStep, isTutorialActive, setTutorialStep } = this.props;
    isTutorialActive && shouldChangeTutorialStep(tutorialStep) && setTutorialStep(MissionTutorialStepsKeys.CHECK_OUT);
  };

  componentDidMount = () => {
    this.updateTutorialStep();
  };

  componentDidUpdate = () => {
    this.updateTutorialStep();
  };

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    return (
      <SHome
        navigation={this.props.navigation}
        {...this.props}
        render={
          /**
           * @param {LivePanelLayoutInfo} args
           */
          args => {
            const { pagedGames, lobbyPromotions } = args;
            const hasPromotions = !Utils.isEmpty(lobbyPromotions);
            const hasGames = !Utils.isEmpty(pagedGames);

            return (
              <div className="roomPage">
                <div className="roomContent">
                  {hasGames && (
                    <GameListWrapper>
                      <div className={'GameListContainer'}>
                        <GamePage {...args} navigation={this.props.navigation} hasPromotions={hasPromotions} />
                      </div>
                    </GameListWrapper>
                  )}
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

