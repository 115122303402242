import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { StoreLink } from '../../styledComponents';
import environmentVariables from 'shared/utils/environmentVariables';

export default function LinkToStore({ storeLink }) {
  return (
    <StoreLink href={storeLink}>
      {isIOS && environmentVariables.APPLE_STORE_URL && 'Available in App Store'}
      {isAndroid && environmentVariables.ANDROID_STORE_URL && 'Available in Google Play'}
    </StoreLink>
  );
}

