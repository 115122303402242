import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEffect } from 'react';
import { usePostEmailVerificationMutation } from '../../../state/query/doubleOptIn';
function useVerifyEmailConfirmation(_ref) {
  var verificationId = _ref.verificationId;
  var _usePostEmailVerifica = usePostEmailVerificationMutation(),
    _usePostEmailVerifica2 = _slicedToArray(_usePostEmailVerifica, 2),
    postEmailVerification = _usePostEmailVerifica2[0],
    _usePostEmailVerifica3 = _usePostEmailVerifica2[1],
    isLoading = _usePostEmailVerifica3.isLoading,
    data = _usePostEmailVerifica3.data,
    error = _usePostEmailVerifica3.error,
    isSuccess = _usePostEmailVerifica3.isSuccess;
  useEffect(function () {
    if (verificationId) postEmailVerification({
      verificationId: verificationId
    });
  }, [verificationId, postEmailVerification]);
  return {
    isLoading: isLoading,
    isSuccess: isSuccess,
    data: data,
    error: error
  };
}
export default useVerifyEmailConfirmation;