import React from 'react';
import styled from 'styled-components';
import BannerItem from './BannerItem';

const Banner = ({ navigation, lobbyBanners, scrolling }) => {
  return (
    <Container>
      {lobbyBanners?.map((banner, index) => (
        <BannerItem key={index} banner={banner} navigation={navigation} scrolling={scrolling} />
      ))}
    </Container>
  );
};

export default Banner;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

