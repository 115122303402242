import styled, { css } from 'styled-components';
import { ACTION_COMMANDS_MODAL_TYPE } from 'shared/api/ActionCommandsInterface/constants';

export const NotificationsPermissionWrapper = styled.div`
  display: block;
  height: 100%;
  ${({ modalType }) => popupWrapperCustomStyles[modalType] || ``}
`;

export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  ${({ modalType }) => customModalTypeImageCss[modalType] || ``}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => theme}
  ${({ modalType }) => customModalTypeButtonCss[modalType] || ``}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: ${({ imageSource }) => (imageSource ? 'flex' : 'none')};
`;

export const ButtonText = {
  fontWeight: '900',
};

export const FacebookLinkButton = {
  width: 200,
  height: 35,
};

export const MemberLinkButton = {
  width: 200,
  height: 37,
  margin: 0,
};

export const CustomerSupportWrapper = styled.div`
  position: absolute;
  left: 22%;
  bottom: 28vh;

  @media screen and (min-height: 1000px) {
    bottom: 30vh;
  }

  @media screen and (min-height: 1300px) {
    bottom: 33vh;
  }
`;

const emailVerficationPopUpModalImage = css`
  margin-bottom: 25vh;
  object-fit: contain;
`;

const customModalTypeImageCss = {
  [ACTION_COMMANDS_MODAL_TYPE.EMAIL_VERIFICATION]: emailVerficationPopUpModalImage,
};

const emailVerificationPoupButton = css`
  bottom: 22vh;
  @media screen and (min-height: 1050px) {
    bottom: 24vh;
  }

  @media screen and (min-height: 1300px) {
    bottom: 28vh;
  }

  @media screen and (max-height: 900px) {
    bottom: 20vh;
  }

  @media screen and (max-height: 800px) {
    bottom: 18vh;
  }
`;

const customModalTypeButtonCss = {
  [ACTION_COMMANDS_MODAL_TYPE.EMAIL_VERIFICATION]: emailVerificationPoupButton,
};

const emailVerificationPoupWrapper = css`
  display: flex;
  height: 60vh;
  max-height: 60rem;
  justify-content: center;
  align-items: center;
`;

const popupWrapperCustomStyles = {
  [ACTION_COMMANDS_MODAL_TYPE.EMAIL_VERIFICATION]: emailVerificationPoupWrapper,
};

