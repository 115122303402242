import LinkAccountForm from './LinkAccountForm';
import { connect } from '../../node_modules/react-redux';
import { setCasinoId as _setCasinoId, setCasinoIdStatus as _setCasinoIdStatus, isAccountLinked as _isAccountLinked, setCurrentMemberLevel as _setCurrentMemberLevel } from '../../state/actions/playerInfo';
import { fetchProperties } from '../../state/query/properties';
var mapStateToProps = function mapStateToProps(state) {
  var linkedLoyaltyCardBonus = state.load.initAppResponse.SETTINGS.linkedLoyaltyCardBonus;
  return {
    linkedLoyaltyCardBonus: linkedLoyaltyCardBonus,
    assetsUrls: state.app.assetsUrls,
    userProperty: state.properties.data.userProperty,
    propertySelector: state.properties.data.propertySelector,
    enableOrderCard: state.properties.data.enableOrderCard,
    copys: state.properties.data.textsFile
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setCasinoId: function setCasinoId(id) {
      dispatch(_setCasinoId(id));
    },
    setCasinoIdStatus: function setCasinoIdStatus(status) {
      dispatch(_setCasinoIdStatus(status));
    },
    isAccountLinked: function isAccountLinked(value) {
      dispatch(_isAccountLinked(value));
    },
    setCurrentMemberLevel: function setCurrentMemberLevel(level) {
      dispatch(_setCurrentMemberLevel(level));
    },
    getFetchProperties: function getFetchProperties() {
      return dispatch(fetchProperties.initiate());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LinkAccountForm);