export var FTUE_STEPS_KEYS = {
  INITIAL: 0,
  HIGHLIGHT_A_GAME: 1,
  SHOW_IN_GAME: 2,
  CLICKED_ON_SPINNER: 3,
  FINISHED_AND_SHOW_POPUP: 4
};
export var FTUE_EVENT_TYPES = {
  START_GAME_TUTORIAL: 'START_GAME_TUTORIAL',
  GAME_TUTORIAL_NEXT_STEP: 'GAME_TUTORIAL_NEXT_STEP',
  DISPLAY_GAME_TUTORIAL_STEP: 'DISPLAY_GAME_TUTORIAL_STEP',
  GAME_TUTORIAL_SKIP: 'GAME_TUTORIAL_SKIP',
  GAME_TUTORIAL_COMPLETE: 'GAME_TUTORIAL_COMPLETE'
};
export var FTUE_MESSAGE_POSITION = {
  LEFT: 'left'
};