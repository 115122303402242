import { useCallback, useEffect, useState } from 'react';
import { Endpoints } from 'shared/api/Constants';
import { AxiosRequest } from 'shared/state/api/services/axios';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { clearAllSavedData, clearAllCookies, geoCheckStatus } from '../utils';
import { HTTP_STATUS } from 'shared/state/api/services/utils/constants';

export default function useGeoCheck() {
  const [ipLockStatus, changeIpStatus] = useState(geoCheckStatus.CHECKING);
  const [geoCheckMessage, changeMessage] = useState('');

  const checkuserLocation = useCallback(async () => {
    try {
      await AxiosRequest(Endpoints.GEO_CHECK, methodType, fetchParams);
      changeIpStatus(geoCheckStatus.PASS);
    } catch (error) {
      setTimeout(() => {
        clearAllSavedData();
        clearAllCookies();
      }, 2000);
      changeIpStatus(geoCheckStatus.BLOCKED);

      const errorMessage =
        error?.response?.data?.message || error?.response?.status === HTTP_STATUS.UNAUTHORIZED
          ? getText(TEXT_KEY.GEO_CHECK_ERROR_MESSAGE)
          : getText(TEXT_KEY.TRY_AGAIN_OR_CONTACT_SUPPORT);

      changeMessage(errorMessage);
    }
  }, []);

  useEffect(() => {
    checkuserLocation();
  }, [checkuserLocation]);

  return {
    ipLockStatus,
    geoCheckMessage,
  };
}

const methodType = 'GET';
const fetchParams = {
  method: methodType,
  mode: 'cors',
  cache: 'no-cache',
  redirect: 'follow',
  referrer: 'no-referrer',
};

