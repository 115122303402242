import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { fetchPropertiesQuery, fetchTextsQuery } from './queries';
export var propertiesApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      fetchProperties: builder.query(fetchPropertiesQuery),
      fetchTexts: builder.query(fetchTextsQuery)
    };
  }
});
var useFetchPropertiesQuery = propertiesApi.useFetchPropertiesQuery,
  useFetchTextsQuery = propertiesApi.useFetchTextsQuery,
  fetchProperties = propertiesApi.endpoints.fetchProperties;
export { useFetchPropertiesQuery, useFetchTextsQuery, fetchProperties };