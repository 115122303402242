import { createSwitchNavigator } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';
import React from 'react';

/* Screens Imports */
import Screens from 'shared/screens';
import StoreDownload from './screens/StoreDownload';
import DoubleOptIn from './screens/DoubleOptIn';
import navigation from 'shared/utils/navigation/navigation';
import QueryParamProxy from 'shared/utils/queryParamProxy';

// Init QPP
QueryParamProxy.init();

const screens = {
  [Screens.StoreDownload]: StoreDownload,
  [Screens.DoubleOptIn]: {
    screen: DoubleOptIn,
    path: 'emailverification/:pev',
  },
};

const RootStack = createSwitchNavigator(screens, {
  initialRouteName: Screens.StoreDownload,
});

const MobileApp = createBrowserApp(RootStack);

const MobileAppComponent = props => {
  return (
    <MobileApp
      ref={navigatorRef => {
        navigation.setTopLevelNavigator(navigatorRef);
      }}
    />
  );
};

export default MobileAppComponent;

