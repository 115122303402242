import { DOUBLE_OPT_IN_CACHE_TAGS } from '../tags';
import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getEmailVerificationStatusQuery, postEmailVerificationMutation, postSendEmailVerificationMutation } from './queries';
export var doubleOptInApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postEmailVerification: builder.mutation(postEmailVerificationMutation),
      postSendEmailVerification: builder.mutation(postSendEmailVerificationMutation),
      getEmailVerificationStatus: builder.query(getEmailVerificationStatusQuery)
    };
  }
});
doubleOptInApi.enhanceEndpoints({
  addTagTypes: [DOUBLE_OPT_IN_CACHE_TAGS.DOUBLE_OPT_IN]
});
var useLazyGetEmailVerificationStatusQuery = doubleOptInApi.useLazyGetEmailVerificationStatusQuery,
  useGetEmailVerificationStatusQuery = doubleOptInApi.useGetEmailVerificationStatusQuery,
  usePostEmailVerificationMutation = doubleOptInApi.usePostEmailVerificationMutation,
  _doubleOptInApi$endpo = doubleOptInApi.endpoints,
  getEmailVerificationStatus = _doubleOptInApi$endpo.getEmailVerificationStatus,
  postEmailVerification = _doubleOptInApi$endpo.postEmailVerification,
  postSendEmailVerification = _doubleOptInApi$endpo.postSendEmailVerification;
export { useLazyGetEmailVerificationStatusQuery, useGetEmailVerificationStatusQuery, usePostEmailVerificationMutation, getEmailVerificationStatus, postEmailVerification, postSendEmailVerification };