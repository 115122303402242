import React from 'react';
import useBannerItem from 'shared/components/Banner/hooks/useBannerItem';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import cdnPath from 'shared/utils/cdnPath';
import styled from 'styled-components';
import { useSelector } from 'shared/node_modules/react-redux';

const BannerItem = ({ navigation, banner: { bannerType, gameIds }, scrolling }) => {
  const { openBanner, setOpenBanner, toggleBanner, handleOpenGame, bannerBackground } = useBannerItem({
    navigation,
    bannerType,
    scrolling,
  });

  const lobbyPromotions = useSelector(state => state.promotions.lobbyNews);

  return (
    <>
      <BannerCard
        onClick={() => setOpenBanner(!openBanner)}
        background={assetSource(bannerBackground[bannerType])}
        slides={lobbyPromotions.length}
      />
      <BannerDetail
        isOpen={openBanner}
        background={assetSource(ASSET_KEY.LOBBY_BANNERS_SLIDER_MENU)}
        slides={lobbyPromotions.length}
      >
        <BannerContent isOpen={openBanner}>
          {Object.values(gameIds)
            .slice(0, 4)
            .map((gameId, index) => (
              <ContentImage
                key={`${gameId}-banner-${index}`}
                src={`${cdnPath.SLOT_ICON_MOBILE}/${gameId}.png`}
                onClick={() => handleOpenGame(gameId)}
              />
            ))}
        </BannerContent>
      </BannerDetail>
      <CloseButton onClick={toggleBanner} isOpen={openBanner} src={assetSource(ASSET_KEY.LOBBY_BANNERS_ARROW_BUTTON)} />
    </>
  );
};
export default BannerItem;

const BannerCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21vw;
  height: 28vw;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: 87% 100%;
  background-position: center;
  position: relative;
  transition: 0.7s ease;
  top: ${({ slides }) => (slides > 1 ? 1 : 2)}vh;
  cursor: pointer;
  z-index: 1;

  @media only screen and (min-width: 1200px) and (max-width: 1350px) and (min-height: 900px) and (max-height: 1020px) {
    padding-bottom: ${({ slides }) => (slides > 1 ? 0 : '1.5rem')};
    width: ${({ slides }) => (slides > 1 ? '21vw' : '26vw')};
    height: ${({ slides }) => (slides > 1 ? 37 : 46)}vh;
    margin-bottom: ${({ slides }) => (slides > 1 ? 1 : 4.5)}rem;
  }

  @media only screen and (max-height: 900px) and (min-width: 1930px) {
    width: 18vw;
  }

  @media only screen and (max-height: 880px) {
    width: ${({ slides }) => (slides > 1 ? 21 : 22.5)}rem;
    height: ${({ slides }) => (slides > 1 ? 51.1 : 53)}vh;
    margin-bottom: ${({ slides }) => (slides > 1 ? 0.5 : 1.2)}rem;

    @media only screen and (max-width: 1250px) {
      width: ${({ slides }) => (slides > 1 ? 17 : 18.7)}rem;
      height: ${({ slides }) => (slides > 1 ? 41 : 47.5)}vh;
      margin-bottom: ${({ slides }) => (slides > 1 ? 0 : 1)}rem;
    }
  }

  @media only screen and (max-height: 800px) {
    width: ${({ slides }) => (slides > 1 ? 18 : 19)}rem;
    margin-top: ${({ slides }) => (slides > 1 ? 0 : 0.5)}rem;
    height: ${({ slides }) => (slides > 1 ? 48 : 51)}vh;
    margin-bottom: 0.7rem;

    @media only screen and (max-width: 1400px) {
      margin-bottom: 0px;
      height: ${({ slides }) => (slides > 1 ? 50 : 51)}vh;
      margin-top: ${({ slides }) => (slides > 1 ? 0.4 : 0)}rem;
    }

    @media only screen and (max-width: 1200px) {
      width: ${({ slides }) => (slides > 1 ? 15 : 17)}rem;
      height: ${({ slides }) => (slides > 1 ? 43.3 : 47)}vh;
      margin-top: ${({ slides }) => (slides > 1 ? 1.4 : -1)}rem;
    }
  }

  @media only screen and (max-height: 700px) {
    width: ${({ slides }) => (slides > 1 ? 14 : 15)}rem;
    padding-bottom: ${({ slides }) => (slides > 1 ? 0 : 1)}rem;
    height: ${({ slides }) => (slides > 1 ? 43 : 45)}vh;
  }

  @media only screen and (max-height: 600px) {
    width: ${({ slides }) => (slides > 1 ? 11.3 : 11.3)}rem;
    height: 40vh;
    margin-top: ${({ slides }) => (slides > 1 ? 0.2 : 0.5)}rem;
  }

  @media only screen and (max-height: 560px) {
    width: 9.3rem;
    height: ${({ slides }) => (slides > 1 ? 34.5 : 37)}vh;
    margin-bottom: ${({ slides }) => (slides > 1 ? 0.7 : 1.5)}rem;
  }
`;

const BannerDetail = styled.div`
  top: ${({ slides }) => (slides > 1 ? 1 : 2)}vh;
  height: 28vw;
  width: ${({ isOpen }) => (isOpen ? 29 : 0)}vw;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  right: 4vw;
  transition: 0.7s ease;
  overflow: hidden;

  @media only screen and (min-width: 1200px) and (max-width: 1350px) and (min-height: 900px) and (max-height: 1020px) {
    height: ${({ slides }) => (slides > 1 ? 37.3 : 45.5)}vh;
    top: ${({ slides }) => (slides > 1 ? 0.16 : -1.6)}vh;
  }

  @media only screen and (max-height: 900px) and (min-width: 1930px) {
    width: 18vw;
  }

  @media only screen and (max-height: 880px) {
    height: ${({ slides }) => (slides > 1 ? 51.1 : 52)}vh;
    top: ${({ slides }) => (slides > 1 ? 0.5 : 0.9)}vh;

    @media only screen and (max-width: 1250px) {
      height: ${({ slides }) => (slides > 1 ? 40.5 : 46)}vh;
      top: 1vh;
    }
  }

  @media only screen and (max-height: 800px) {
    height: ${({ slides }) => (slides > 1 ? 48 : 50)}vh;
    top: ${({ slides }) => (slides > 1 ? 0.2 : 1.8)}vh;

    @media only screen and (max-width: 1400px) {
      top: ${({ slides }) => (slides > 1 ? 1.5 : 2)}vh;
    }

    @media only screen and (max-width: 1200px) {
      height: ${({ slides }) => (slides > 1 ? 43.3 : 46.3)}vh;
      top: ${({ slides }) => (slides > 1 ? 2.8 : 1)}vh;
    }
  }

  @media only screen and (max-height: 700px) {
    height: ${({ slides }) => (slides > 1 ? 43 : 44.2)}vh;
    top: ${({ slides }) => (slides > 1 ? 0 : 1.8)}vh;
    @media only screen and (max-width: 1400px) {
      top: ${({ slides }) => (slides > 1 ? 1.5 : 2)}vh;
    }

    @media only screen and (max-width: 1200px) {
      top: ${({ slides }) => (slides > 1 ? 2.8 : 1)}vh;
    }
  }

  @media only screen and (max-height: 600px) {
    height: 40vh;
    top: ${({ slides }) => (slides > 1 ? 0 : 2)}vh;
    @media only screen and (max-width: 1400px) {
      top: ${({ slides }) => (slides > 1 ? 1.5 : 2.6)}vh;
    }
  }

  @media only screen and (max-height: 560px) {
    height: ${({ slides }) => (slides > 1 ? 34.5 : 36)}vh;
    top: 0.8vh;
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s ease ${({ isOpen }) => (isOpen ? '0.6s' : '0s')};
`;

const ContentImage = styled.img`
  z-index: 10;
  width: 11vw;
  height: 21vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  object-fit: contain;
  @media only screen and (max-height: 560px) {
    height: 17vh;
  }
`;

const CloseButton = styled.img`
  z-index: 2;
  width: 3.5vw;
  height: 3.5vw;
  margin-left: ${({ isOpen }) => (isOpen ? -6 : -3)}vw;
  cursor: pointer;
  transition: 0.7s ease all;
  ${({ isOpen }) => !isOpen && `transform: rotateY(180deg)`};
`;

