import { PLAYER_TAGS } from '../tags';
import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postAcceptNewTermsMutation, postChangePasswordMutation, postDeDupeCheckMutation, getDeletePlayerAccountMutation, getCasinoDollarBalanceQuery, getPlayerProfileQuery, postKnowYourCustomerMutation, postRecoverPasswordMutation, postRecoverPasswordTokenCheckMutation, getToggleMusicMutation, getToggleSoundMutation, postUpdatePlayerProfileMutation } from './queries';
export var playerApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getPlayerProfile: builder.query(getPlayerProfileQuery),
      postUpdatePlayerProfile: builder.mutation(postUpdatePlayerProfileMutation),
      postDeDupeCheck: builder.mutation(postDeDupeCheckMutation),
      postKnowYourCustomer: builder.mutation(postKnowYourCustomerMutation),
      getCasinoDollarBalance: builder.query(getCasinoDollarBalanceQuery),
      getToggleSound: builder.mutation(getToggleSoundMutation),
      getToggleMusic: builder.mutation(getToggleMusicMutation),
      postChangePassword: builder.mutation(postChangePasswordMutation),
      postRecoverPasswordTokenCheck: builder.mutation(postRecoverPasswordTokenCheckMutation),
      postRecoverPassword: builder.mutation(postRecoverPasswordMutation),
      postAcceptNewTerms: builder.mutation(postAcceptNewTermsMutation),
      getDeletePlayerAccount: builder.mutation(getDeletePlayerAccountMutation)
    };
  },
  overrideExisting: true
});
playerApi.enhanceEndpoints({
  addTagTypes: [PLAYER_TAGS.GET_PLAYER_PROFILE, PLAYER_TAGS.CASINO_DOLLAR_BALLANCE],
  keepUnusedDataFor: 10
});
var usePostRecoverPasswordMutation = playerApi.usePostRecoverPasswordMutation,
  usePostUpdatePlayerProfileMutation = playerApi.usePostUpdatePlayerProfileMutation,
  useGetDeletePlayerAccountMutation = playerApi.useGetDeletePlayerAccountMutation,
  _playerApi$endpoints = playerApi.endpoints,
  getPlayerProfile = _playerApi$endpoints.getPlayerProfile,
  postUpdatePlayerProfile = _playerApi$endpoints.postUpdatePlayerProfile,
  postDeDupeCheck = _playerApi$endpoints.postDeDupeCheck,
  postKnowYourCustomer = _playerApi$endpoints.postKnowYourCustomer,
  getCasinoDollarBalance = _playerApi$endpoints.getCasinoDollarBalance,
  getToggleSound = _playerApi$endpoints.getToggleSound,
  getToggleMusic = _playerApi$endpoints.getToggleMusic,
  postChangePassword = _playerApi$endpoints.postChangePassword,
  postRecoverPasswordTokenCheck = _playerApi$endpoints.postRecoverPasswordTokenCheck,
  postRecoverPassword = _playerApi$endpoints.postRecoverPassword,
  postAcceptNewTerms = _playerApi$endpoints.postAcceptNewTerms,
  getDeletePlayerAccount = _playerApi$endpoints.getDeletePlayerAccount;
export { usePostRecoverPasswordMutation, usePostUpdatePlayerProfileMutation, useGetDeletePlayerAccountMutation, getPlayerProfile, postUpdatePlayerProfile, postDeDupeCheck, postKnowYourCustomer, getCasinoDollarBalance, getToggleSound, getToggleMusic, postChangePassword, postRecoverPasswordTokenCheck, postRecoverPassword, postAcceptNewTerms, getDeletePlayerAccount };