var generateEventsInfoScreenRender = function generateEventsInfoScreenRender(eventsTexts) {
  return [{
    header: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenHeader,
    title: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenTargetedEventsTitle,
    description: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenTargetedEventsDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_ONE_ICON'
  }, {
    header: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenHeader,
    title: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenSweepstakesTitle,
    description: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenSweepstakesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_TWO_ICON'
  }, {
    header: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenHeader,
    title: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenEarnEntriesTitle,
    description: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenEarnEntriesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_THREE_ICON'
  }, {
    header: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenHeader,
    title: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenCheckYourMessagesTitle,
    description: eventsTexts === null || eventsTexts === void 0 ? void 0 : eventsTexts.infoScreenCheckYourMessagesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_FOUR_ICON'
  }];
};
export default generateEventsInfoScreenRender;