export var ACTION_COMMANDS_TYPE = {
  ACTION_SEQUENCE: 'ACTION_SEQUENCE',
  DISPLAY_MODAL: 'DISPLAY_MODAL',
  DISPLAY_TOOLTIP: 'DISPLAY_TOOLTIP',
  DISPLAY_GAME_TUTORIAL: 'DISPLAY_GAME_TUTORIAL',
  DISPLAY_MISSIONS_TUTORIAL: 'DISPLAY_MISSIONS_TUTORIAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  NOTIFY_TRIGGER_PROCESSED_REQUEST: 'NOTIFY_TRIGGER_PROCESSED_REQUEST',
  NOTIFY_TRIGGER_DISMISSED_REQUEST: 'NOTIFY_TRIGGER_DISMISSED_REQUEST',
  TOGGLE_ALLOW_MARKETING_EMAILS_REQUEST: 'TOGGLE_ALLOW_MARKETING_EMAILS_REQUEST',
  TOGGLE_ALLOW_NOTIFICATIONS_REQUEST: 'TOGGLE_ALLOW_NOTIFICATIONS_REQUEST',
  SUBMIT_EVENT_REQUEST: 'SUBMIT_EVENT_REQUEST',
  CLEAR_ACTION_COMMAND: 'CLEAR_ACTION_COMMAND',
  DISPLAY_EVENTS_TUTORIAL: 'DISPLAY_EVENTS_TUTORIAL',
  NOTIFICATION: 'NOTIFICATION',
  SYNC_PLAYER_WALLET: 'SYNC_PLAYER_WALLET',
  HTTP_REQUEST: 'HTTP_REQUEST'
};
export var ACTION_COMMANDS_MODAL_TYPE = {
  ALLOW_MARKETING_EMAILS: 'ALLOW_MARKETING_EMAILS',
  ALLOW_NOTIFICATIONS: 'ALLOW_NOTIFICATIONS',
  SAVE_YOUR_PROGRESS: 'SAVE_YOUR_PROGRESS',
  DAILY_WHEEL: 'DAILY_WHEEL',
  DAILY_WHEEL_ONBOARDING: 'DAILY_WHEEL_ONBOARDING',
  WELCOME_BONUS: 'WELCOME_BONUS',
  WELCOME_NEWS: 'WELCOME_NEWS',
  LIMTED_TIME_OFFER: 'LTO',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  DEFAULT: 'DEFAULT'
};
export var ACTION_COMMANDS_TOOLTIP_TYPE = {
  CARD_LINK: 'CARD_LINK',
  REWARD_CENTER: 'REWARD_CENTER',
  EVENT_ABOUT_TO_END: 'EVENT_ABOUT_TO_END',
  EVENT_COMPLETED: 'EVENT_COMPLETED',
  EVENT_EXPIRED: 'EVENT_EXPIRED',
  DEFAULT: 'DEFAULT'
};
export var ACTION_COMMANDS_COMPONENT_TYPE = {
  MODAL: 'MODAL',
  BUTTON: 'BUTTON',
  GAME_TUTORIAL: 'GAME_TUTORIAL'
};
export var ACTION_COMMANDS_BUTTON_TYPE = {
  ACCEPT: 'ACCEPT',
  CANCEL: 'CANCEL',
  CLOSE: 'CLOSE',
  LOGIN_GOOGLE: 'LOGIN_GOOGLE',
  LOGIN_APPLE: 'LOGIN_APPLE',
  LOGIN_FACEBOOK: 'LOGIN_FACEBOOK',
  LOGIN_MAIL: 'LOGIN_MAIL',
  RESEND_AND_TIMER: 'RESEND_AND_TIMER'
};
export var ACTIONS_COMMANDS_ASSETS_CATEGORY_TYPE = {
  ALLOW_MARKETING_EMAILS: 'ALLOW_MARKETING_EMAILS_MODAL',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION_MODAL',
  ALLOW_NOTIFICATIONS: 'ALLOW_NOTIFICATIONS_MODAL',
  SAVE_YOUR_PROGRESS: 'SAVE_YOUR_PROGRESS_MODAL',
  DEFAULT: 'DEFAULT'
};
export var ACTION_COMMANDS_TRIGGER_TYPE = {
  PURCHASE: 'PURCHASE',
  REDEEM_OFFER: 'REDEEM_OFFER',
  LEVEL_UP: 'LEVEL_UP',
  ENTER_LOBBY: 'ENTER_LOBBY',
  BACK_TO_LOBBY: 'BACK_TO_LOBBY',
  COLLECT_TIMED_BONUS: 'COLLECT_TIMED_BONUS',
  COLLECT_WELCOME_BONUS: 'COLLECT_WELCOME_BONUS',
  TOGGLE_EMAIL_OPT_IN: 'TOGGLE_EMAIL_OPT_IN',
  ALLOW_MARKETING_EMAILS: 'ALLOW_MARKETING_EMAILS'
};
export var ACTION_COMMANDS_FEATURE_GROUP = {
  FTUE: 'FTUE',
  POPUPS: 'POPUPS',
  DAILY_WHEEL: 'DAILY_WHEEL'
};
export var ACTION_COMMANDS_HTTP_REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'UPDATE',
  UPDATE: 'DELETE'
};